import type { ButtonHTMLAttributes, HTMLAttributes } from 'react'

import { cnva } from '~/utils/cn'

const tagStyle = cnva(
  'inline-flex items-center gap-1.5 rounded-md bg-foreground/4 px-3 py-2 text-xs font-medium text-foreground transition-all',
  {
    variants: {
      interactive: {
        true: 'select-none hover:bg-foreground/6 active:bg-foreground/8 disabled:opacity-48',
      },
    },
  }
)

type CommonProps = {
  label: string
  suffixIcon?: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, 'ref'> &
      React.RefAttributes<SVGSVGElement>
  >
  prefixIcon?: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, 'ref'> &
      React.RefAttributes<SVGSVGElement>
  >
}

type InteractiveProps = CommonProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
    onClick: NonNullable<ButtonHTMLAttributes<HTMLButtonElement>['onClick']>
  }
type NonInteractiveProps = CommonProps &
  Omit<HTMLAttributes<HTMLDivElement>, 'onClick'>

function Tag({
  className,
  suffixIcon: SuffixIconComponent,
  prefixIcon: PrefixIconComponent,
  label,
  ...props
}: InteractiveProps | NonInteractiveProps) {
  if ('onClick' in props) {
    return (
      <button className={tagStyle({ interactive: true, className })} {...props}>
        <TagInner
          label={label}
          suffixIcon={SuffixIconComponent}
          prefixIcon={PrefixIconComponent}
        />
      </button>
    )
  } else {
    return (
      <div className={tagStyle({ className })} {...props}>
        <TagInner
          label={label}
          suffixIcon={SuffixIconComponent}
          prefixIcon={PrefixIconComponent}
        />
      </div>
    )
  }
}

const TagInner = ({
  label,
  suffixIcon: SuffixIconComponent,
  prefixIcon: PrefixIconComponent,
}: CommonProps) => {
  return (
    <>
      {PrefixIconComponent && (
        <span className="text-foreground/64">
          <PrefixIconComponent width={'18px'} height={'18px'} />
        </span>
      )}

      <p className="flex-1 text-left"> {label}</p>

      {SuffixIconComponent && (
        <span className="text-foreground/64">
          <SuffixIconComponent width={'18px'} height={'18px'} />
        </span>
      )}
    </>
  )
}

export { Tag }
