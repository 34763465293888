import { type SVGProps, forwardRef } from 'react'

export const Beta = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      ref={ref}
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.88 17.729c2.47 0 4.544-1.864 4.544-4.44v-.02c0-1.293-.364-2.52-1.378-3.387a4.2 4.2 0 0 0-1.318-.751c.195-.119.38-.258.55-.42.803-.766 1.057-1.81 1.057-2.834v-.021c0-1.104-.414-2.108-1.215-2.824C12.335 2.329 11.284 2 10.16 2c-1.335 0-2.542.425-3.38 1.44-.8.968-1.095 2.274-1.095 3.688v9.349c0 .837-.333 1.64-.925 2.23a.65.65 0 1 0 .92.92 4.455 4.455 0 0 0 1.304-3.15v-.364c.052.057.104.113.159.167.963.966 2.287 1.449 3.736 1.449Zm0-1.3c1.807 0 3.244-1.336 3.244-3.14v-.02c0-2.11-1.164-3.214-3.888-3.214h-.203a.817.817 0 0 1 0-1.633h.215c1.97 0 2.787-.89 2.787-2.545v-.021c0-1.538-1.124-2.556-2.874-2.556-2.11 0-3.176 1.304-3.176 3.828v5.345c0 2.429 1.576 3.956 3.894 3.956Z"
        clipRule="evenodd"
      />
    </svg>
  )
)

Beta.displayName = 'Beta'
